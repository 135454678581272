/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { setCreateMatkul, setUpdateMatkul } from 'services/matakuliah';
import Cookies from 'js-cookie';
import { optionJenisMatkul } from 'helpers/optionData';

const maxYear = new Date().getFullYear();
const minYear = 2022
const yearArray = []

let i = 0;
do {
    yearArray.push({
      label: `${String(maxYear-i)}/${String(maxYear - i + 1)}`,
      value: `${String(maxYear-i)}/${String(maxYear - i + 1)}`,
    });
    i++;
} while (maxYear-i >= minYear)

const ModalMataKuliah = ({ isOpen, toggle, created, getData, setLoading, data, listProdi, view }) => {

  const profileCookies = Cookies.get('_P01e')
  const profileDecode = JSON.parse(atob(profileCookies))

  const [mataKuliah, setMatakuliah] = useState('');
  const [kodeMk, setKodeMk] = useState('');
  const [prodi, setProdi] = useState('');
  const [tahunAkademik, setTahunAkdemik] = useState('');
  const [sksTapmuk, setSksTapmuk] = useState(0);
  const [sksPraktikum, setSksPraktikum] = useState(0);
  const [sksPraktikLapangan, setSksPraktikLapangan] = useState(0);
  const [semester, setSemester] = useState('');
  const [jenisMatkul, setJenisMatkul] = useState('');
  const [jenisPembelajaran, setJenisPembelajaran] = useState('');
  const [fileRps, setFileRps] = useState('');
  const [previewRps, setPreviewRps] = useState('');

  useEffect(() => {
    if (!created && data) {
      if (data.jenis_matkul) {
        const valueJenisMatkul = optionJenisMatkul.filter(val => val.value === data.jenis_matkul)[0]
        setJenisMatkul(valueJenisMatkul)
      } else {
        setJenisMatkul('')
      }
      if (data.tahun_akademik) {
        const valueTahunAkademik = yearArray.filter(val => val.value === data.tahun_akademik)[0]
        setTahunAkdemik(valueTahunAkademik)
      } else {
        setTahunAkdemik('')
      }
      if (profileDecode?.Role?.name !== 'Prodi') {
        if (data.id_prodi) {
          const valueProdi = listProdi.filter(val => val.value === data.id_prodi)[0]
          setProdi(valueProdi)
        } else {
          setProdi('')
        }
      }
      if (data.rps) {
        setPreviewRps(`https://backend.siakadpoltekeskmc.ac.id/rps/${data.rps}`)
      }
      setMatakuliah(data.mata_kuliah)
      setKodeMk(data.kode_mk)
      setSksTapmuk(data.sks_tapmuk)
      setSemester(data.semester)
      setJenisPembelajaran(data.metode_pembelajaran)
      setSksPraktikum(data.sks_praktikum)
      setSksPraktikLapangan(data.sks_praktik_lapangan)
    } else {
      setMatakuliah('');
      setKodeMk('');
      setProdi('');
      setSksTapmuk(0);
      setSksPraktikum(0);
      setSksPraktikLapangan(0);
      setSemester('');
      setJenisMatkul('');
      setJenisPembelajaran('');
      setTahunAkdemik('');
      setPreviewRps('');
      setFileRps('');
    }
  }, [isOpen])

  const submit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('mata_kuliah', mataKuliah)
    formData.append('kode_mk', kodeMk)
    formData.append('id_prodi', profileDecode?.Role?.name === 'Prodi' ? profileDecode?.id_prodi : prodi?.value)
    formData.append('sks_tapmuk', sksTapmuk)
    formData.append('sks_praktikum', sksPraktikum)
    formData.append('sks_praktik_lapangan', sksPraktikLapangan)
    formData.append('semester', semester)
    formData.append('jenis_matkul', jenisMatkul?.value)
    formData.append('metode_pembelajaran', jenisPembelajaran)
    formData.append('total_sks', Number(sksTapmuk) + Number(sksPraktikLapangan) + Number(sksPraktikum))
    
    if (fileRps) {
      formData.append('rps', fileRps)
    }

    if (!mataKuliah || !kodeMk || (profileDecode?.Role?.name !== 'Prodi' && !prodi) || !sksTapmuk || !semester || !jenisMatkul) {
      Swal.fire({
        icon: 'error',
        title: 'Isian Form bertanda bintang harus diisi!!!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      let response
      if (created) {
        response = await setCreateMatkul(formData)
      } else {
        response = await setUpdateMatkul(formData, data.id)
      }
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: response.message
        }).then(() => {
          toggle()
          getData()
        })
      }
    }
  }

  const onChangeRps = (e) => {
    if (e.target.files[0].type === 'application/pdf') {
      setFileRps(e.target.files[0]);
      setPreviewRps(URL.createObjectURL(e.target.files[0]));
    } else {
      Swal.fire('Error', 'File harus bertipe pdf', 'error')
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>{view ? 'Detail' : created ? "Buat" : "Update"} Mata Kuliah</ModalHeader>
      <ModalBody className="pt-1">
        <Form onSubmit={submit}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Kode MK</Label>
                <Input
                  type="text"
                  placeholder="Drop some text here..."
                  value={kodeMk}
                  onChange={(e) => setKodeMk(e.target.value)}
                  disabled={view}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Mata Kuliah</Label>
                <Input
                  type="text"
                  placeholder="Drop some text here..."
                  value={mataKuliah}
                  onChange={(e) => setMatakuliah(e.target.value)}
                  disabled={view}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Semester</Label>
                <Input
                  type="number"
                  placeholder="Drop some text here..."
                  value={semester}
                  onChange={(e) => setSemester(e.target.value)}
                  disabled={view}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Bobot Tatap Muka (SKS)</Label>
                <Input
                  type="number"
                  placeholder="Drop some text here..."
                  value={sksTapmuk}
                  onChange={(e) => setSksTapmuk(e.target.value)}
                  disabled={view}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Bobot Praktikum (SKS)</Label>
                <Input
                  type="number"
                  placeholder="Drop some text here..."
                  value={sksPraktikum}
                  onChange={(e) => setSksPraktikum(e.target.value)}
                  disabled={view}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Bobot Praktik Lapangan (SKS)</Label>
                <Input
                  type="number"
                  placeholder="Drop some text here..."
                  value={sksPraktikLapangan}
                  onChange={(e) => setSksPraktikLapangan(e.target.value)}
                  disabled={view}
                />
              </FormGroup>
            </Col>
            <Col md={6} hidden={profileDecode?.Role?.name === 'Prodi'}>
              <FormGroup>
                <Label className='required'>Program Studi</Label>
                <Select
                  name="program-studi"
                  placeholder="Select Program Studi"
                  options={listProdi}
                  defaultValue={prodi}
                  onChange={(e) => setProdi(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className='required'>Jenis Mata Kuliah</Label>
                <Select
                  name="teori-praktek"
                  placeholder="Select Jenis Mata Kuliah"
                  options={optionJenisMatkul}
                  defaultValue={jenisMatkul}
                  onChange={(e) => setJenisMatkul(e)}
                  isDisabled={view}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Metode Pembelajaran</Label>
                <Input
                  type="text"
                  placeholder="Drop some text here..."
                  value={jenisPembelajaran}
                  onChange={(e) => setJenisPembelajaran(e.target.value)}
                  disabled={view}
                />
              </FormGroup>
            </Col>
            <Col md={6} hidden>
              <FormGroup>
                <Label className='required'>Tahun Akademik</Label>
                <Select
                  name="tahun-akademik"
                  placeholder="Select Tahun Akademik"
                  options={yearArray}
                  defaultValue={tahunAkademik}
                  onChange={(e) => setTahunAkdemik(e)}
                  isDisabled={view}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label hidden={previewRps ? true : false}>Upload RPS (PDF)</Label>
                <Input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => onChangeRps(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6} hidden={previewRps ? false : true}>
              <Button type="button" onClick={() => window.open(previewRps)}>Preview</Button>
            </Col>
          </Row>
          <Button color="primary" type="submit" hidden={view}>Simpan Data</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ModalMataKuliah;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { setCreatePedoman, setUpdatePedoman } from 'services/pedoman';

const ModalPedoman = ({ isOpen, toggle, created, getData, setLoading, data, view }) => {

  const [name, setName] = useState('');
  const [filePedoman, setFilePedoman] = useState('');
  const [previewPedoman, setPreviewPedoman] = useState('');

  useEffect(() => {
    if (!created && data) {
      if (data.pedoman) {
        setPreviewPedoman(`https://backend.siakadpoltekeskmc.ac.id/pedoman/${data.pedoman}`)
      }
      setName(data.name)
    } else {
    setName('');
    setPreviewPedoman('');
    setFilePedoman('');
    }
  }, [isOpen])

  const submit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('name', name)
    
    if (filePedoman) {
      formData.append('pedoman', filePedoman)
    }

    if (!name) {
      Swal.fire({
        icon: 'error',
        title: 'Isian Form bertanda bintang harus diisi!!!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      let response
      if (created) {
        response = await setCreatePedoman(formData)
      } else {
        response = await setUpdatePedoman(formData, data.id)
      }
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: response.message
        }).then(() => {
          toggle()
          getData()
        })
      }
    }
  }

  const onChangePedoman = (e) => {
    if (e.target.files[0].type === 'application/pdf') {
      setFilePedoman(e.target.files[0]);
      setPreviewPedoman(URL.createObjectURL(e.target.files[0]));
    } else {
      Swal.fire('Error', 'File harus bertipe pdf', 'error')
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>{view ? 'Detail' : created ? "Buat" : "Update"} Pedoman / SOP</ModalHeader>
      <ModalBody className="pt-1">
        <Form onSubmit={submit}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label className='required'>Nama Pedoman / SOP</Label>
                <Input
                  type="text"
                  placeholder="Drop some text here..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={view}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label hidden={previewPedoman ? true : false}>Upload Pedoman / SOP (PDF)</Label>
                <Input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => onChangePedoman(e)}
                />
              </FormGroup>
            </Col>
            <Col md={6} hidden={previewPedoman ? false : true}>
              <Button type="button" onClick={() => window.open(previewPedoman)}>Preview</Button>
            </Col>
          </Row>
          <Button color="primary" type="submit" hidden={view}>Simpan Data</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ModalPedoman;

import React, { useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const LandingPage = () => {

  const history = useHistory()

  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <>
      <Container className="header-landing">
        <Row className="align-items-center">
          <Col md={6}>
            <h1 className="title-banner">
               SISTEM AKADEMIK POLITEKNIK KESEHATAN
              <br />
              <span className="underline-blue"> KMC KUNINGAN</span>
            </h1>
            <div>
              <span>Yuk Login Sekarang.</span>
            </div>
            <div className='mb-3'>
              <Button color="primary" className="px-5 mt-3" type='button' onClick={() => history.push('/auth')}>
                Login
              </Button>
            </div>
            <div>
              <span>Atau Ingin Melihat Dokumen RPS / Pedoman / SOP</span>
            </div>
            <div>
              <Button color="primary" className="px-5 mt-3" type='button' onClick={() => history.push('/rps')}>
                RPS
              </Button>
              <Button color="primary" className="px-5 mt-3" type='button' onClick={() => history.push('/pedoman')}>
                Pedoman / SOP
              </Button>
            </div>
          </Col>
          <Col md={6}>
            <img
              alt="..."
              src={
                require("assets/img/icons/common/banner_image.svg")
                  .default
              }
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </>
    )
}

export default LandingPage
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Card,
    CardHeader,
    Container,
    Row,
    Table,
    CardFooter,
    Button,
    Input,
    Col,
} from "reactstrap";
import Header from "components/Headers/Header";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Pagination from 'react-js-pagination';
import useDebounce from 'helpers/useDebounce';
import usePrevious from "helpers/usePrevious";
import Loader from 'components/Loader/Loader';
import { getListPedoman, deletePedoman } from "services/pedoman";
import ModalPedoman from "components/Modal/Pedoman";


const Pedoman = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [created, setCreated] = useState(true);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('')
    const [pageInfo, setPageInfo] = useState({
        totalData: 0,
        totalPage: 0
    })
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false);
    const [dataModal, setDataModal] = useState('');
    const debounceSearch = useDebounce(search, 500);

    const toggle = () => setIsOpen(!isOpen);

    const prevSearch = usePrevious(debounceSearch)

    useEffect(() => {
        getData()
    }, [debounceSearch])

    const getData = async (value, refresh) => {
        setLoading(true)
        let newPage
        if (prevSearch !== debounceSearch || refresh) {
            newPage = 1
        }
        const params = {
            page: value ? value : newPage,
            search
        }
        const response = await getListPedoman(params)
        if (response.error) {
            Swal.fire({
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            setData(response.results.data)
            const pageInfo = {
                totalData: response.results.count,
                totalPage: response.results.pageCount
            }
            setPageInfo(pageInfo)
            if (prevSearch !== debounceSearch || refresh) {
                setPage(newPage)
            }
        }
        setLoading(false)
    }

    const deleteData = async (id) => {
        Swal.fire({
            icon: 'question',
            title: 'Hapus Data?',
            text: 'Apakah Anda Yakin?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then(async (value) => {
            if (value.isConfirmed) {
                setLoading(true)
                const response = await deletePedoman(id)
                if (response.error) {
                    Swal.fire({
                        icon: 'error',
                        title: response.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: response.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    getData(1, 'refresh')
                }
                setLoading(false)
            }
        })
    }

    const handleChangePage = (page) => {
        setPage(page)
        getData(page)
    }

    return (
        <>
            {loading && <Loader />}
            <ModalPedoman
                toggle={() => toggle()}
                setLoading={setLoading}
                isOpen={isOpen}
                created={created}
                getData={() => getData(1, 'refresh')}
                data={dataModal}
            />
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Daftar Pedoman / SOP</h3>
                                <Row className="mt-3">
                                    <Col md="4">
                                        <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    </Col>
                                    <Col md="8" className="d-flex justify-content-end align-items-center mt-3 mt-sm-0">
                                        <Button color="primary" onClick={() => {
                                            setCreated(true)
                                            toggle()
                                        }} size="sm">
                                            <i className="fa fa-plus" />
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <div className="table-wrap">
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Nama Pedoman / SOP</th>
                                            <th scope="col">File</th>
                                            <th scope="col" style={{ width: 150 }}>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? data.map((val, idx) => {
                                            return (
                                                <tr key={String(idx)}>
                                                    <th scope="row">
                                                        {val.name}
                                                    </th>
                                                    <th scope="row">
                                                        {val.pedoman ? <Button type="button" onClick={() => window.open(`https://backend.siakadpoltekeskmc.ac.id/pedoman/${val.pedoman}`)}>Preview</Button> : '-'}
                                                    </th>
                                                    <td>
                                                        <Button type="button" size="sm" onClick={() => {
                                                            setCreated(false);
                                                            setDataModal(val);
                                                            toggle();
                                                        }}>
                                                            <i className="fa fa-edit" />
                                                        </Button>
                                                        <Button type="button" size="sm" onClick={() => deleteData(val.id)}>
                                                            <i className="fa fa-trash text-danger" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        }) : (
                                            <tr>
                                                <th colSpan="2" className="text-center">
                                                    Data Empty
                                                </th>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            <CardFooter className="py-4">
                                {pageInfo.totalData > 0 && (
                                    <div className="d-flex justify-content-end mt-4">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={15}
                                            totalItemsCount={pageInfo.totalData}
                                            pageRangeDisplayed={3}
                                            onChange={handleChangePage.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                )}
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Pedoman;

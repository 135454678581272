import callAPI from './api'

const ROOT_API = process.env.REACT_APP_API_URL
const url = `${ROOT_API}pedoman`

export const getListPedoman = async (params) => {

  return callAPI({
    url,
    method: 'GET',
    params,
    token: true
  })
}

export const setCreatePedoman = async (data) => {

  return callAPI({
    url,
    method: 'POST',
    data,
    token: true
  })
}

export const setUpdatePedoman = async (data, id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'PUT',
    data,
    token: true
  })
}

export const deletePedoman = async (id) => {

  return callAPI({
    url: `${url}/${id}`,
    method: 'DELETE',
    token: true
  })
}
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Card,
    CardHeader,
    Container,
    Row,
    Table,
    CardFooter,
    Button,
    Input,
    Col,
} from "reactstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Pagination from 'react-js-pagination';
import useDebounce from 'helpers/useDebounce';
import usePrevious from "helpers/usePrevious";
import Loader from 'components/Loader/Loader';
import Header from "components/Headers/Header";
import { getListPedoman } from "services/pedoman";


const Pedoman = () => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('')
    const [pageInfo, setPageInfo] = useState({
        totalData: 0,
        totalPage: 0
    })
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false);
    const debounceSearch = useDebounce(search, 500);

    const prevSearch = usePrevious(debounceSearch)

    useEffect(() => {
        getData()
    }, [debounceSearch])

    const getData = async (value, refresh) => {
        setLoading(true)
        let newPage
        if (prevSearch !== debounceSearch || refresh) {
            newPage = 1
        }
        const params = {
            page: value ? value : newPage,
            search
        }
        const response = await getListPedoman(params)
        if (response.error) {
            Swal.fire({
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            setData(response.results.data)
            const pageInfo = {
                totalData: response.results.count,
                totalPage: response.results.pageCount
            }
            setPageInfo(pageInfo)
            if (prevSearch !== debounceSearch || refresh) {
                setPage(newPage)
            }
        }
        setLoading(false)
    }

    const handleChangePage = (page) => {
        setPage(page)
        getData(page)
    }

    return (
        <>
            {loading && <Loader />}
            <Header />
            <Container className="mt--7">
                <Row className="align-items-center">
                    <Col md={1}>
                        <div>
                            <img
                                alt="..."
                                src={
                                    require("../assets/img/brand/logo_kmc.png").default
                                }
                                width={60}
                                height={60}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <h3 className="mb-0 text-white">Daftar Pedoman / SOP</h3>
                    </Col>
                    <Col md={5} />
                    <div className="col mt-3">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="mt-3">
                                    <Col md="4">
                                        <Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <div className="table-wrap">
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Nama Pedoman / SOP</th>
                                            <th scope="col">File</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? data.map((val, idx) => {
                                            return (
                                                <tr key={String(idx)}>
                                                    <th scope="row">
                                                        {val.name}
                                                    </th>
                                                    <th scope="row">
                                                        {val.pedoman ? <Button type="button" onClick={() => window.open(`https://backend.siakadpoltekeskmc.ac.id/pedoman/${val.pedoman}`)}>Preview</Button> : '-'}
                                                    </th>
                                                </tr>
                                            )
                                        }) : (
                                            <tr>
                                                <th colSpan="2" className="text-center">
                                                    Data Empty
                                                </th>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            <CardFooter className="py-4">
                                {pageInfo.totalData > 0 && (
                                    <div className="d-flex justify-content-end mt-4">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={15}
                                            totalItemsCount={pageInfo.totalData}
                                            pageRangeDisplayed={3}
                                            onChange={handleChangePage.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                )}
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Pedoman;

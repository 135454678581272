import Index from "views/admin/Index.js";
import Profile from "views/admin/Profile";
import LevelUser from "views/admin/LevelUser";
import User from "views/admin/User";
import ProgramStudi from "views/admin/ProgramStudi";
import MataKuliah from "views/admin/MataKuliah";
import JadwalKuliah from "views/admin/JadwalKuliah";
import Kelas from "views/admin/Kelas";
import JadwalUjian from "views/admin/JadwalUjian";
import Aktivasi from "views/admin/Aktivasi";
import TahunAkademik from "views/admin/TahunAkademik";
import Penilaian from "layouts/PenilaianMahasiswa";
import Khs from "views/admin/Khs";
import Pedoman from "views/admin/Pedoman";

const routesAdmin = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/level-user",
    name: "Level User",
    icon: "fa fa-layer-group text-primary",
    component: LevelUser,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "Mahasiswa & Dosen",
    icon: "fa fa-users text-primary",
    component: User,
    layout: "/admin"
  },
  {
    path: "/program-studi",
    name: "Program Studi",
    icon: "fa fa-graduation-cap text-primary",
    component: ProgramStudi,
    layout: "/admin"
  },
  {
    path: "/penilaian-mahasiswa",
    name: "Penilaian Mahasiswa",
    icon: "fa fa-layer-group text-primary",
    component: Penilaian,
    layout: "/admin"
  },
  {
    path: "/khs",
    name: "KHS",
    icon: "fa fa-layer-group text-primary",
    component: Khs,
    layout: "/admin"
  },
  {
    path: "/mata-kuliah",
    name: "Mata Kuliah",
    icon: "ni ni-books text-primary",
    component: MataKuliah,
    layout: "/admin"
  },
  {
    path: "/kelas",
    name: "Kelas",
    icon: "ni ni-building text-primary",
    component: Kelas,
    layout: "/admin"
  },
  {
    path: "/jadwal-kuliah",
    name: "Jadwal Kuliah",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: JadwalKuliah,
    layout: "/admin"
  },
  {
    path: "/jadwal-ujian",
    name: "Jadwal Ujian",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: JadwalUjian,
    layout: "/admin"
  },
  {
    path: "/aktivasi",
    name: "Aktivasi",
    icon: "ni ni-key-25 text-primary",
    component: Aktivasi,
    layout: "/admin"
  },
  {
    path: "/tahun-akademik",
    name: "Tahun Akademik",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: TahunAkademik,
    layout: "/admin"
  },
  {
    path: "/pedoman",
    name: "Pedoman",
    icon: "ni ni-books text-primary",
    component: Pedoman,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/admin"
  }
];
export default routesAdmin;

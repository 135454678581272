import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
} from "reactstrap";
import { setLogin } from "services/auth";
import Swal from "sweetalert2";
import Cookies from 'js-cookie';
import jwtDecode from "jwt-decode";

const Login = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const submit = async (e) => {
    e.preventDefault()
    const data = {
      email,
      password
    }

    if (!email || !password) {
      Swal.fire({
        icon: 'error',
        title: 'Email atau Password harus diisi!!!',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      setLoading(true)
      const response = await setLogin(data)
      if (response.error) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: response.message,
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        Swal.fire({
          icon: 'success',
          title: response.message,
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          setLoading(false)
          const token = response.results.token
          const tokenBase64 = btoa(token)
          const roleBase64 = btoa(response.results.role)
          const profile = jwtDecode(token)
          const profileBase = btoa(JSON.stringify(profile))
          Cookies.set('_P01e', profileBase, { expires: 1 })
          Cookies.set('_T0123', tokenBase64, { expires: 1 })
          Cookies.set('_R8l', roleBase64, { expires: 1 })
          if (response.results.role === 'Administrator') {
            history.push('/admin')
          } else if (response.results.role === 'Dosen') {
            history.push('/dosen')
          } else if (response.results.role === 'Mahasiswa') {
            history.push('/mahasiswa')
          } else if (response.results.role === 'Prodi') {
            history.push('/prodi')
          } else if (response.results.role === 'Akademik' || response.results.role === 'Kepala Bagian Akademik' || response.results.role === 'Wakil Direktur 1') {
            history.push('/akademik')
          }
        })
      }
    }
  }

  return (
    <Card className="bg-secondary shadow border-0">
      <CardHeader className="bg-transparent">
        <div className="mt-2 mb-3 px-lg-4">
          <span className="text-primary h2 font-weight-bold">
            Selamat Datang di Sistem Akademik POLITEKNIK KESEHATAN KMC KUNINGAN
          </span>
        </div>
      </CardHeader>
      <CardBody className="px-lg-5 py-lg-4">
        <Form role="form" onSubmit={submit}>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Email"
                type="email"
                autoComplete="new-email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Password"
                type="password"
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <Link to="/auth/lupa-password">
            <span className="text-danger font-weight-bold">Lupa Password?</span>
          </Link>
          <div className="mt-3">
            <span className="text-muted">
              jika Anda lupa kata sandi, buat permintaan password baru dengan
              mengklik “Lupa Password” di atas dan isi form email yang tersedia
              pada halaman Lupa Password.
            </span>
          </div>
          <div className="text-right">
            {loading ? (
              <Spinner size="lg" color="dark" />
            ) : (
              <Button className="my-4" color="primary" type="submit">
                Login
              </Button>
            )}
          </div>
          <div>
            <Button color="primary" className="px-5 mt-3" type='button' onClick={() => history.push('/rps')}>
              RPS
            </Button>
            <Button color="primary" className="px-5 mt-3" type='button' onClick={() => history.push('/pedoman')}>
              Pedoman / SOP
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Login;
